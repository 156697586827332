import React, { Component } from 'react';
import './App.css';
import 'tachyons'

import Menu from './Menu'

import Home from './components/Home'
//import Portfolio from './components/Portfolio'
import { Route, Link, Switch, Redirect } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import { Dispatch } from '@Corim/architecture-frontend'
//import AboutUs from './components/AboutUs'
import history from './shared/architecture/history'

// const MilkerBrochurePage1 = require('./media/Milker_Brochure_Page_1.png')
// const MilkerBrochurePage2 = require('./media/Milker_Brochure_Page_2.png')
// const milkerlogo = require('./media/milkerlogo.jpg')
// const milkerWebsite = require('./media/milkerWebsite.png')
// const ptaLogo = require('./media/ptalogo.png')
// const pta = require('./media/pta.png')
// const ptaBanner = require('./media/ptaBanner.png')
// const ptaInvade = require('./media/ptaInvade.png')

// const kinoveLogo = require('./media/kinoveLogo.svg')
// const kinoveDanceStudio = require('./media/kinoveDanceStudio.png')
// const kinoveOffice = require('./media/kinoveOffice.png')
// const kinoveKitchen = require('./media/kinoveKitchen.png')

// const mepronAd = require('./media/mepronAd.png')

// const lidmSample = require('./media/lidmSample.png')
// const lidmClaim = require('./media/lidmClaim.png')

// const LIDM = (props) => {
//   return (
//     <div className='pt4 bg-white'>
//       <Item
//         image={lidmSample}
//         align='tr'
//         headline='Sample Input Screen'
//         tags='custom software, workflow, business logic'
//         selectable={false}
//       />
//       {/* <Item
//         image={lidmClaim}
//         align='tl'
//         headline='Claim Input Screen'
//         tags='custom software, workflow, business logic'
//         selectable={false}
//         width='30'
//       /> */}
//     </div>
//   )
// }

// const Mepron = (props) => {
//   return (
//     <div className='pt4 bg-white'>
//       <Item
//         image={mepronAd}
//         align='tr'
//         headline='"Milk Comes From Teats" - Campaign'
//         tags='photography, magazine ad, copy'
//         width='50'
//         selectable={false}
//       />
//     </div>
//   )
// }
// const Kinove = (props) => {
//   return (
//     <div className='pt4 bg-white'>
//       <Item
//         image={kinoveLogo}
//         align='tr'
//         headline='Kinove Logo'
//         tags='logo'
//         width='50'
//       />
//       <Item
//         image={kinoveOffice}
//         align='tl'
//         headline='Window Poster'
//         tags='poster, photography'
//       />
//       <Item
//         image={kinoveDanceStudio}
//         align='tr'
//         headline='Window Poster'
//         tags='poster, photography'
//       />
//       <Item
//         image={kinoveKitchen}
//         align='tl'
//         headline='Window Poster'
//         tags='poster, photography'
//       />
//     </div>
//   )
// }

// const PTA = (props) => {
//   return (
//     <div className='mw7 center pt4 bg-white'>
//       <Item
//         image={ptaLogo}
//         align='tr'
//         headline='PTA Logo'
//         tags='logo'
//         width='50'
//       />
//       <Item
//         image={pta}
//         align='tl'
//         headline='Postcard Front'
//         tags='postcard'
//       />
//       <Item
//         image={ptaInvade}
//         align='tr'
//         headline='Large Invasion Banner'
//         tags='banner'
//       />
//     </div>
//   )
// }

// const Milker = (props) => {
//   return (
//     <div className='w-100 pt4 bg-black'>
//       {/* <div className='f3 fw5 tc pb4'>
//         Milker - The Dairy Decision Engine
//       </div> */}
//       <div className='mw7 center pt4 '>
//       <Item
//         image={milkerlogo}
//         align='tr'
//         headline='Milker Logo'
//         tags='logo'
//         width='50'
//       />
//       <Item
//         image={MilkerBrochurePage1}
//         align='tl'
//         headline='Milker Brochure Front'
//         tags='brochure'
//       />
//       <Item
//         image={MilkerBrochurePage2}
//         align='tr'
//         headline='Milker Brochure Back'
//         tags='brochure'
//       />
//       <Item
//         image={milkerWebsite}
//         align='tl'
//         headline='Milker Website'
//         tags='website'
//       />
//       </div>
//     </div>
//   )
// }

class App extends Component {
  render() {
    return (
      <div className='w-100 avenir'>

        {/* <div className='h4 pb6 mb4 mw7 center'
          style={{lineHeight: 'normal'}}>
          <div className='h3 bg-white'> </div>
          <div className='f2 fr mr3 mr5-ns pr1 pl1 pb1 bg-white relative pointer grow' style={{top: -20}}>
            <Menu />
          </div>
          <div className='fl ml3 ml5-ns w4 pr3 pl3 pb1 bg-white relative pointer grow' style={{top: -23, width: 200}}>
            <img
              onClick={() => history.push('/home')}
              src={logo}
              alt="logo" />
          </div>
        </div> */}
        <div className='fixed pv2 ph2 pointer grow' style={{fontSize: '50', zIndex:1000}}>
            <Menu {...this.props}/>
          </div>
        <Home {...this.props}/>
       
        {/* <div
          className='absolute f5 mw7 w-100 center
          tr bg-dark-gray pt3 mt4 pb3 pr3 white'
          style={{bottom: 0}}>

          2018 Dither LLC. All Rights Reserved. &nbsp;
          <Link
            style={{ textDecoration: 'none' }}
            to='/terms'>Terms and Conditions
          </Link>
        </div> */}
        {/* <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{marginTop: 'auto'}}>TESSSSSST</div>
        </div> */}
      </div>


    );
  }
}

export default App;
