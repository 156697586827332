import React, { Component } from 'react';
import Item from './Item'
import { Dispatch } from '@Corim/architecture-frontend'
import 'tachyons'
import { fromEvent } from 'rxjs';
import * as Rx from 'rxjs'
import { map, startWith, tap } from 'rxjs/operators';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import BaseComponent from './BaseComponent'
import Portfolio from './Portfolio'

import ditherBackground from '../media/Inklink Video.mp4'
import logo from '../media/inklink logo.svg'
import videoBackground from '../media/Inklink Video Background.jpg'

const scrollDistance$ = fromEvent(document, 'scroll')
const size$ = fromEvent(window, 'resize')
  .pipe(
    map(e => ({ height: e.target.innerHeight, width: e.target.innerWidth})),
    startWith({ height: window.innerHeight, width: window.innerWidth})
  )

const displayIntroExplanation$ = scrollDistance$
  .pipe(
    map(e => (window.innerHeight - (e.target.documentElement.scrollTop + e.target.body.scrollTop) > 0 )),
    startWith( true)
  )

const store$ = Rx.combineLatest(
    scrollDistance$.pipe(startWith(0)), 
    displayIntroExplanation$,
    size$,
  )
  .pipe(
    map(([
      scroll, 
      displayIntroExplanation, 
      size]) => ({scroll, displayIntroExplanation, size}))
  )

const Home = (props) => {
  const displayed = props.displayIntroExplanation ? 'block' : 'none'
  const scroll = props.scroll
  const height = props.size.height
  const width = props.size.width
  const slantHeight = props.size.width/22
  return (
    <div>
      <div className='fixed w-100' style={{zIndex: -2, height}}>
        <video src={ditherBackground} poster={videoBackground} className='o-70 db' style={{minWidth: '100%', minHeight: '100%', zIndex: -2, position: 'relative', left: '50%', transform: 'translateX(-50%) translateY(-50%)', top: '50%'}} autoPlay loop muted playsInline>
         
        </video>
      </div>
        <div className='fixed w-100 flex items-center' style={{height}}>
          <div className='w-100 mw6 center'>
            <img
                  className='w-100'
                  //onClick={() => history.push('/home')}
                  src={logo}
                  alt="Inklink Logo"
                />
          </div>
        </div>
        {/* <div className={'fixed w-100 h3 bg-black'}>
          
        
            
            
        </div> */}

      
        {/* <div className='w-100 f1 flex justify-center' style={{height: '22vh'}}>
          <div onClick={e=>document.getElementById('intro').scrollIntoView()} className='black fixed pulse pointer grow o-30'>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          
        </div> */}
        

        {/* <div className='w-100' style={{height: '70vh'}}></div> */}
        <Portfolio slantHeight={slantHeight} {...props}/>
    </div>
  )
}

export default (props) => <BaseComponent Component={Home} store={store$} {...props}/>