import React, { Component } from 'react';
import 'tachyons'
import _ from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import { map, tap, take, startWith, delay, filter } from 'rxjs/operators'
import { combineLatest } from 'rxjs'
import { Dispatch, Textbox, MultiSelect, BaseComponent, SubmitButton } from '@Corim/architecture-frontend'

//const profile = require('../../media/VProfile.png')
import profile from '../../media/SarahProfile.png'
import loading from './media/loading.svg'
import '../../contact'

export default (props) => {
  return (
    <div id="contact" className='w-100 bg-white black ph4 pb5 '>
      
      
             <img className='' src={profile} height='180' alt="Vanessa Profile Picture" />
       <div className='f2 fw6'>Kayleen</div>
       <div>Curator & Book Connoisseur</div>
       <div className='pt4 f5 fw7'>Tues-Sat: 10am-1pm</div>
               <div className='pt1 f5 fw7'>2890 Main St. #225 East Troy, WI 53120</div>
               <div className='mw6 w-100'>

          <Textbox
            className='w-100 mt4 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.name'
            placeholder='Name'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.email'
            placeholder='Email'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv4 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.message'
            placeholder='Message'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <div className='pt3 w-100 flex justify-center justify-between-ns'>
            <div>
            </div>
            <SubmitButton id={'contactSubmit'} className='flex pt2 justify-between' 
              showSuccess={true}
              defaultChild={ props => (
                <div className='dib ph4 pv3 br2 bg-blue white pointer fw6' onClick={e => Dispatch.nextAction('contact.send')}>Submit</div>
              )}
              loadingChild={ props => (
                <div className='w-100 flex justify-between'>
                  <div></div>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              successChild={ props => (
                <div className='dib ph4 pv3 br2 bg-blue white pointer fw6'>Success</div>
              )}
              errorChild={ props => (
                <div className='pl3 f5 fw7 red'>Error</div>
              )}
              loadingStream={
                Dispatch.getAction('Request.contact')
                }
              successStream={
                Dispatch.getAction('Response.contact')
                  .pipe(
                    delay(500),
                    filter(e => _.isNil(e.errors)),
                  )
                }
              errorStream={Dispatch.getAction('Response.contact')
              .pipe(
                filter(e => !_.isNil(e.errors)),
              )}
            />
          </div>
        </div>
      </div>
  )}



// import React, { Component } from 'react';
// import 'tachyons'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
// //const profile = require('../../media/VProfile.png')
// const profile = require('../../media/SarahProfile.png')
// const loadingIcon = require('./media/loading.svg')
// export default (props) => {
//   return (
//     <div id="contact" className='w-100 bg-white black ph4 pb5 '>
      
      
//       <img className='' src={profile} height='180' alt="Vanessa Profile Picture" />
//       <div className='f2 fw6'>Kayleen</div>
//       <div>Curator & Book Connoisseur</div>
//       <div className='pt4'>Visit Tuesday - Saturday, 10am - 1pm</div>
//               <div className='fw6'>2890 Main St. East Troy, WI 53120</div>
//       <label className="f4 b db mb1 mt5 fw3 tl">Name <span className="normal black-60"></span></label>
//       <input
//         className="input-reset ba b--black-20 pa2 mt2 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         value={props.form.name}
//         onChange={e => props.handleUpdateForm('name', e.target.value)}
//       />
//       <label className="f4 b db mb1 mt2 fw3 tl">Company <span className="normal black-60"></span></label>
//       <input
//         className="input-reset ba b--black-20 pa2 mt2 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         value={props.form.company}
//         onChange={e => props.handleUpdateForm('company', e.target.value)}
//       />
//       <label className="f4 b db mb1 mt2 fw3 tl">Email <span className="normal black-60"></span></label>
//       <input
//         className="input-reset ba b--black-20 pa2 mt2 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         value={props.form.email}
//         onChange={e => props.handleUpdateForm('email', e.target.value)}
//       />
//       <label className="f4 b db mb1 mt2 fw3 tl">Message <span className="normal black-60"></span></label>
//       <textarea
//         className="h4 input-reset ba b--black-20 pa2 mt2 mb2 w-100"
//         type="text"
//         aria-describedby="name-desc"
//         value={props.form.message}
//         onChange={e => props.handleUpdateForm('message', e.target.value)}
//       />
//       {/* <label className="f4 b db mb1 mt2 fw3 tl">Contact <span className="normal black-60"></span></label> */}
//       {/* <div
//         className=' pt3 f5'>
//         <input
//           className=''
//           type="radio"
//           defaultChecked
//           name="contactPerson"
//           onChange={e => props.handleUpdateForm('contact', 'sales')}
//         /><span className='pl3'>Sales Member</span>
//         <div className='w-100 pv1'/>
//         <input
//           className=''
//           type="radio"
//           name="contactPerson"
//           onChange={e => props.handleUpdateForm('contact', 'claims')}
//         /><span className='pl3'>Claim Specialist</span>
//       </div> */}
//       {props.submitState === 'pending' ?
//       <PendingState />
//       :
//       <div
//         className='ba br2 ph3 pv3 mt5 mw5 tc center white'
//         onClick={e =>
//           props.handleSubmit()
//         }
//         style={{backgroundColor: "#4d642d"}}> { props.submitState}
//       </div>
//       }
//     </div>
//   )}

// const PendingState = (props) => {
//   return (
//     <div className='pv3 mt4 w3 center'>
//       <img className='w-100' src={loadingIcon}  alt="Logo" />
//     </div>
//   )
// }
