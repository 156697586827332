import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Dispatch, Textbox, MultiSelect, BaseComponent, Request } from '@Corim/architecture-frontend'
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Router, Route, Link } from "react-router-dom";
import history from './shared/architecture/history'

import Apollo from './apollo'

Request(Dispatch, Apollo)

ReactGA.initialize('UA-136335287-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <Router history={history}>
    <BaseComponent Component={App} />
  </Router>, document.getElementById('root'));
registerServiceWorker();
