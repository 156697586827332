import React, { Component } from 'react';
import Item from './Item'
import { Dispatch } from '@Corim/architecture-frontend'
import ContactForm from '../shared/ContactForm/Contact'
import 'tachyons'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import inklinkInterior1 from '../media/inklink interior1.jpg'
import inklinkInterior2 from '../media/inklink interior2.jpg'
import inklinkExterior2 from '../media/inklink exterior2.jpg'
import slant from '../media/BlackSlant.svg'
import blackSlantBackward from '../media/BlackSlantBackward.svg'
import greenSlant from '../media/GreenSlant.svg'
import inklinkExterior from '../media/Inklink Exterior.jpg'

export default class App extends Component {
  constructor (props) {
    super(props)
    this.state = { readMore1: false, readMore2: false }
  }
  render() {
    const slantHeight = this.props.slantHeight
    return (
    <div className='w-100' > 
      <div className='w-100 flex items-end' style={{height: '96vh'}}> </div>
        
      <div className='relative'>
        <div className='relative w-100' style={{top: 1, height: slantHeight}}></div>
        {/* <img className='relative w-100 db' src={greenSlant} alt="" /> */}
        <div
        className='relative pl3 pr3 f2 fw7 pt4 pb4 white'
        style={{backgroundColor: "#4d642d"}}>
          <div className=' ph2 pv4 mw7 center f2 f1-ns flex flex-wrap justify-center tc'>
            Your journey begins at InkLink.
          </div>
        </div>
      </div>

      <div id='about' className='relative w-100 bg-black'>
        <div className='mw8 center pv5 ph4 pb4-ns'>
          
          <img className='w-100 br3 pb1 pt5-ns' src={inklinkExterior} alt="Inklink Books. Dream Big." />
          <div className='tr'><h2 className='f6 f5-ns white fw4 mb3'>InkLink Books in East Troy, WI</h2></div>
          <div className='mw6 center'>
            <div className='ph2-ns white pb2 pb5-ns pt3 tj'>
                    <div className=''><h2 className='f3 f1-ns fw7 mb3'>InkLink Books</h2></div>
                    <div className=' f5 f4-ns fw5 '>
                    Nestled inside a lantern lit doorway, InkLink Books' fireplace flickers as the door opens and the scent of ink printed paper welcomes you. 
                    From above, the goddesses of wisdom watch, urging you onward, teasing you towards the perfect book. Your perfect book. 
                    The book you curl up with next to a fire, warm drink in hand. 
                    The book whose crisp pages hold the key to an infinitude of lives and people and stories. 
                    And you. The book that unlocks you. A new you. A wiser you.
                    {/* <br/><br/>
                    Located on the historic East Troy square, Inklink books lets the magic come true. Visit us and see really great things. Make awesome times and adventures.
                     */}
                    <br/>
                    <br/>
                    We <FontAwesomeIcon className='f1 white pulse pointer grow' style={{color: "#4d642d"}} icon={faHeart} /> Books!
                    </div>
                  </div>
            </div>
        </div>
      </div>
      {/* Case Study */}
      {/* <div className='relative w-100 bg-black' >
        <div className='relative w-100 h1 h4-ns' style={{background: 'url(' + blackSlantBackward + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(1,-1)'}}></div>
        <div className='w-100 mw7 center ph3 white'>
          <div className='f2 f1-ns fw7 pb1 pt2'>Inklink Books</div>
          <div className='f4 f3-ns fw5 pb4'>East Troy, WI</div>
          <div className='f5 f4-ns fw6 pb1'>Basic Case Study:</div>
          <div className='f5 f4-ns fw4 pb4 tj'><span className='i'>Installing flame based exterior lighting: how data science changes high initial costs and continually reocurring costs into ROI. </span></div>
          <img className='w-100 br3 pb1' src={inklinkExterior} alt="Exterior image of Inklink Books bookstore" />
          <div className='relative f6 w-100 tr i pb3 pointer'>Exterior of Inklink Books with outdoor gas wall lanterns</div>
          <div className={this.state.readMore1 ? 'dn' : 'relative f4 w-100 tr blue pb2 pointer'} onClick={e => this.setState({ readMore1: !this.state.readMore1 })}>Read More</div>
          <div className={this.state.readMore1 ? 'pt1' : 'dn'}>
            <div className='f5 f4-ns fw6 pb2 pt1 '>Backstory:</div>
            <div> Inklink Books - located in East Troy, WI - needed help making a decision: 
            should outdoor gas wall lanterns (flame based lighting) be installed on the exterior of the building? <br/> <br/>Not only is the initial investment cost considerably higher for gas lanterns but also the reocurring monthly cost of operation.</div>
              <div className='f5 f4-ns fw6 pb2 pt4 '>Analysis:</div>
              <div className='pb3 tj'><span className='i'>1. Identify Quantifiable Metrics </span><br/><br/> 
                In order to attribute a value to a decision we need to identify the goals of the decision. 
                <br/><br/>
                <span className='i'>Why install gas lanterns instead of electric lanterns? </span> One possible answer is to gain word-of-mouth engagement.
                <br/><br/>
                <span className='i'>2. Attribute Values to Metrics</span>
                <br/><br/>
                Next we need to identify the value of a word-of-mouth engagement. We have no direct way to measure this value. But we can use analogous engagement values to create useful approximations. 
                In this case, Google Adwords provides a decent basis to approximate the average cost per engagement for our target audience: ~10$.
              </div>
              <img className='w-100 br3' src={engagementGraph} alt="A graph portraying investment returns" />
              <div className='f7 pb2 tj'>The graph illustrates the ROI of gas lanterns based on 
                the assumption that a word-of-mouth engagement is similar in value
                to a Google Adword engagement at ~$10 and that the initial upfront costs of the gas lanterns is an additional ~$2500 with a reoccuring monthly cost of ~$30/month.
              </div>
          </div>
        </div>
      </div> */}

      {/* Green Band */}
      <div className='relative bg-black'>
        <div className='relative w-100 ' style={{top: 1, height: slantHeight}}></div>
        <div
        className='relative pl3 pr3 f2 fw7 pt4 pb4 white'
        style={{backgroundColor: "#4d642d"}}>
          <div className=' ph2 pv4 mw7 center f2 f1-ns flex flex-wrap justify-center'>
            <div className='ph2 tc'>Lose yourself.</div> <div className='tc'>Find yourself.</div> 
          </div>
        </div>
        
      </div>
      
      {/* <div className='relative w-100 h4 h4-ns mb5' style={{background: 'url(' + blackSlantBackward + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(1,-1)'}}></div> */}
      <div className='' style={{marginBottom: '40vh'}}> </div>
      <div className='relative w-100 mt5' style={{top: 1, height: slantHeight}}></div>
      <div className='relative'>
        {/* <div id='portfolio' className='w-100 h1 h4-ns' style={{background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover'}}></div> */}
        <div id='portfolio'
        className='relative pl3 pr3 f2 fw7 pt4 pb4 white bg-black'
        >
          <div className=' ph2 pv4 mw7 center f2 f1-ns tc'>
            <div className='ph2 tc'>Inspiration.</div> <div className='tc'>Learn & Grow.</div>
          </div>
        </div>
      </div>
      <div className='relative bg-white'>
      <div className='relative w-100' style={{top: -1, height: slantHeight}}></div>
        <div className='mw8 center ph3 pv4 pv3-ns'>
          <img className='w-100 br4 mv3' src={inklinkInterior1} alt="Inklink Books. Dream Big." />
          <img className='w-100 br4 mv3' src={inklinkInterior2} alt="Inklink Books. Dream Big." />
          <img className='w-100 br4 mv3' src={inklinkExterior2} alt="Inklink Books. Dream Big." />
        </div>
        {/* <div className='w-100 h4' style={{background: 'url(' + greenSlant + ')  no-repeat center center', backgroundSize: 'cover', transform: 'scale(-1,-1)'}}></div> */}
      </div>
      

        <div className='relative bg-white'>
          <div className='w-100 h4' ></div>
          <div id='contact'
          className='relative pl3 pr3 f2 fw7 pt4 pb4 white'
          style={{backgroundColor: "#4d642d"}}>
            <div className=' ph2 pv4 mw7 center f2 f1-ns tc'>
              Talk to us. We're here to help.
            </div>
          
          </div>
           
          <div className='w-100 h4' ></div>
         </div>
         

        <div className='relative w-100 bg-white flex items-center flex-column'>

 
          <div className='mw7 center'>
            <ContactForm {...this.props}/>
            {/* <div className='flex justify-center pt3 pb5 '>
              <div className="fb-share-button ph3" data-href="https://inklinkbooks.com" data-layout="button" data-size="large" data-mobile-iframe="true"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdither.studio%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Share</a></div>
              <a className="twitter-share-button ph3"
                href="https://twitter.com/intent/tweet?text=https://inklinkbooks.com"
                data-size="large">
              Tweet</a>
            </div> */}
          </div>
          <div className='h3 w-100 bg-black flex items-center justify-center tc'>
            <div className='white'>Copyright 2019 InkLink Books LLC. Site created by <a className="white" href="https://dither.studio">Dither Studio</a></div>
          </div>
          </div>
          
      </div>

  )
}
}
